<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-03-06 09:22:00
 * @LastEditTime: 2023-07-04 10:06
 * @Descripttion: 教师审核
-->
<style lang="scss" scoped>
.teacher-audit {
    @include innerPage(40px 34px 48px 15px);
    @include pageTitle(0);
    @include defalutTable(calc(100% - 46px), 30px);
    @include pageFoot;
}

.dialog-container {
    box-sizing: border-box;
    padding: 14px 0 40px 0;

    h5 {
        line-height: 30px;
        color: #6340c8;
        margin-bottom: 14px;
    }

    .teacher-info,
    .audit-form {
        box-sizing: border-box;
        padding: 0 12px;
    }

    .audit-form {
        padding-top: 20px;
        margin-top: 28px;
        border-top: 1px solid #e8e8e8;

        .audit-detail {
            width: 100%;
            background: #f6f7fc;
            border-radius: 10px;
            box-sizing: border-box;
            padding: 16px 28px;


        }
    }

    .teacher-detail,
    .audit-detail {

        p {
            width: 100%;
            min-height: 30px;
            box-sizing: border-box;
            padding: 3px 0;
            color: #484848;
            @include flexBox(flex-start, flex-start);

            span {
                display: inline-block;
                line-height: 24px;

                &.label {
                    width: 82px;
                    padding-right: 20px;
                    text-align: right;
                    box-sizing: border-box;
                    flex-shrink: 0;
                }

                &.value {
                    width: calc(100% - 82px);
                }

                &.failed {
                    color: #aeaeae;
                }

                &.primary {
                    color: #f8c436;
                }

                &.success {
                    color: #3bc8af;
                }
            }
        }
    }

    .teacher-detail p {
        color: #1b1b1b;

        span.label {
            width: 90px;
        }
    }
}
</style>

<template>
    <section class="teacher-audit">
        <div class="page-title">
            <h3>教师审核</h3>
            <div class="search-form">
                <el-form inline :model="searchForm">
                    <el-form-item>
                        <el-select v-model="searchForm.teuse_examine" placeholder="选择审核状态" clearable
                            @clear="init('search')">
                            <el-option label="待审核" :value="10"></el-option>
                            <el-option label="已通过" :value="20"></el-option>
                            <el-option label="已驳回" :value="30"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-input v-model.trim="searchForm.teuse_name" maxlength="100" placeholder="输入教师姓名" clearable
                            @clear="init('search')" />
                    </el-form-item>
                </el-form>
                <el-button type="custom_primary" size="medium" @click="init('search')">查询</el-button>
            </div>
        </div>
        <div class="table-box">
            <div class="table-inner" ref="tableInner">
                <el-table :data="tableData" height="100%">
                    <el-table-column align="center" prop="teuse_name" label="教师姓名" min-width="28.15%" />
                    <el-table-column align="center" prop="teuse_account" label="账号" min-width="10.81%" />
                    <el-table-column align="center" prop="status" label="状态" min-width="16.6%">
                        <template slot-scope="scope">
                            <p class="table-status"
                                :class="{ failed: scope.row.teuse_examine == 30, success: scope.row.teuse_examine == 20, warning: scope.row.teuse_examine == 10 }">
                                {{ scope.row.teuse_examine == 30 && '已驳回' || scope.row.teuse_examine == 20 && '已通过' || '待审核'
                                }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="create_time" label="申请时间" min-width="21.72%">
                        <template slot-scope="scope">
                            <p>
                                {{ timeFormat(scope.row.create_time * 1000) }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作" min-width="22.74%">
                        <template slot-scope="scope">
                            <div class="operation-buttons size--2">
                                <el-button type="custom_primary" plain size="mini"
                                    @click="teacherDetail(scope.row.teuser_id)">
                                    查看
                                </el-button>
                                <el-button type="custom_success" plain size="mini" v-if="scope.row.teuse_examine == 10"
                                    @click="teacherDetail(scope.row.teuser_id, true)">
                                    审核
                                </el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="table-foot custom-foot">
                <customPagination :current='searchForm.pageindex' :total="total" @pageChange="flippingPage" />

            </div>
        </div>
        <el-dialog title="审核" width="558px" :show-close="isCheck" :visible.sync="auditDialog" @close="hideDialog">
            <div class="dialog-container">
                <div class="teacher-info">
                    <h5>教师信息</h5>
                    <div class="teacher-detail">
                        <p>
                            <span class="label">教师姓名：</span>
                            <span class="value">{{ checkTeacher.teuse_name }}</span>
                        </p>
                        <p>
                            <span class="label">账号：</span>
                            <span class="value">{{ checkTeacher.teuse_account }}</span>
                        </p>
                        <p>
                            <span class="label">省市区：</span>
                            <span class="value">{{ checkTeacher.teuse_province_city_area || '-' }}</span>
                        </p>
                        <p>
                            <span class="label">校区：</span>
                            <span class="value">{{ checkTeacher.scsch_name }}</span>
                        </p>
                    </div>
                </div>
                <div class="audit-form">
                    <h5>审核详情</h5>
                    <div class="audit-detail" v-if="isCheck">
                        <p>
                            <span class="label">审核</span>
                            <span class="value"
                                :class="{ failed: checkTeacher.teuse_examine == 30, success: checkTeacher.teuse_examine == 20, primary: checkTeacher.teuse_examine == 10 }">
                                {{ checkTeacher.teuse_examine == 30 && '驳回' || checkTeacher.teuse_examine == 20 && '已通过' ||
                                    '待审核' }}
                            </span>
                        </p>
                        <template v-if="checkTeacher.teuse_examine != 10">
                            <p>
                                <span class="label">审核意见</span>
                                <span class="value">{{ checkTeacher.teuse_reason || '-' }}</span>
                            </p>
                            <p>
                                <span class="label">审核人</span>
                                <span class="value">{{ checkTeacher.examine_name || '-' }}</span>
                            </p>
                            <p>
                                <span class="label">审核时间</span>
                                <span class="value">{{ checkTeacher.update_time }}</span>
                            </p>
                        </template>
                    </div>
                    <el-form label-width="84px" ref="auditForm" :model="auditForm" v-if="!isCheck">
                        <el-form-item label="审核：">
                            <el-radio-group v-model="auditForm.teuse_examine" @change="$refs.auditForm.clearValidate();">
                                <el-radio :label="20">通过</el-radio>
                                <el-radio :label="30">驳回</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="审核意见：" prop="teuse_reason"
                            :rules="{ required: auditForm.teuse_examine == 30, message: '请填写审核意见' }">
                            <el-input v-model.trim="auditForm.teuse_reason" type="textarea" maxlength="200"
                                :placeholder="`请输入审核意见（${auditForm.teuse_examine == 30 && '必填' || '选填'}）`" autosize />
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div slot="footer" v-if="!isCheck">
                <el-button type="custom_info" size="small" @click="hideDialog">
                    取 消
                </el-button>
                <el-button type="custom_primary" size="small" :disabled="submitLoad" @click="teacherAudit"
                    v-loading="submitLoad">
                    确 定
                </el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
import { $teacherList, $teacherDetail, $auditTeacher } from "@api/audit";
import customPagination from "@comp/customPagination"
import { formatTime } from "@utils";
export default {
    name: 'audit_teacher',
    components: {
        customPagination
    },
    computed: {
        timeFormat() {
            return function (time) {
                return formatTime(time, 'YYYY-MM-DD')
            }
        }
    },
    data() {
        return {
            searchForm: {
                pageindex: 1,
                pagesize: 10,
            },
            total: 0,
            tableData: [],
            auditDialog: false,
            isCheck: false, // 是否为查看模式
            auditForm: {
                teuse_examine: 20
            },
            checkTeacher: {},
            submitLoad: false,
        }
    },
    created() {
        this.init();
    },
    methods: {
        /** 切换页码 */
        flippingPage(val) {
            this.searchForm.pageindex = val;
            this.init();
        },
        /** 初始化列表数据 */
        async init(val) {
            if (val == "search") this.searchForm.pageindex = 1;
            const res = await $teacherList(this.searchForm);
            if (res.status == 200) {
                this.tableData = res.data.data;
                this.total = res.data.allcount;
            }
        },
        /** 教师详情 */
        async teacherDetail(id, aduit) {
            const res = await $teacherDetail(id);
            if (res.status == 200) {
                this.checkTeacher = res.data;
                this.auditDialog = true;
                this.isCheck = !aduit;
            }
        },
        /** 教师审核 */
        teacherAudit() {
            this.$refs.auditForm.validate(async (valid) => {
                if (valid) {
                    this.submitLoad = true;
                    const res = await $auditTeacher({ ...this.auditForm, teuser_id: this.checkTeacher.teuser_id });
                    this.submitLoad = false;
                    if (res.status == 200) {
                        this.$message.success("审核成功");
                        this.hideDialog();
                        this.init();
                    }
                }
            })
        },
        /** 关闭审核弹窗 */
        hideDialog() {
            this.auditDialog = false;
            this.checkTeacher = {};
            this.$refs.auditForm.resetFields();
            this.auditForm = { scpap_examine: 20 };
        }
    },
}
</script>